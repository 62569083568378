<template>
  <layout>
    <page-title :icon="`lni lni-${id > 0 ? 'pencil' : 'plus'}`" :breadcrumb="breadcrumb">
      {{ id > 0 ? 'Editar Disciplina' : 'Novo Disciplina' }}
    </page-title>
    <div class="card">
      <div v-if="error" class="notification is-danger field">
        <button class="delete" @click="error = null"></button>
        {{ error }}
      </div>
      <div class="card-content">
        <div class="form">
          <div class="field">
            <label class="label" for="name">Nome</label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="name" id="name" placeholder="Nome da disciplina" ref="name" />
            </div>
          </div>
          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send">Salvar Disciplina</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" to="/admin/disciplinas">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import SwalLoading from '@/helpers/SwalLoading'
import { DASHBOARD, DISCIPLINE_LIST, DISCIPLINE_FORM } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'DisciplineForm',
  components: {
    Layout,
    PageTitle
  },
  created () {
    if (this.$route.params.id !== undefined) {
      SwalLoading.fire()

      api.get(`/disciplines/${this.$route.params.id}`).then(res => {
        if (res.status === 200) {
          this.id = res.data.id
          this.name = res.data.name
        } else {
          this.error = 'Não foi possível carregar os dados'
        }
        SwalLoading.close()
      }).catch(() => {
        this.error = 'Não foi possível carregar os dados'
        SwalLoading.close()
      })
    }
  },
  data () {
    return {
      id: 0,
      name: '',
      error: null,
      enviando: false
    }
  },
  computed: {
    breadcrumb () {
      const form = { ...DISCIPLINE_FORM, isActive: true }
      if (this.id > 0) form.text = 'Editar Disciplina'

      return [DASHBOARD, DISCIPLINE_LIST, form]
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.name === '') {
        this.error = 'Nome da disciplina é obrigátorio'
        this.$refs.name.focus()
        this.enviando = false
      }
      this.error = null

      if (this.id === 0) {
        api.post('/disciplines', {
          name: this.name
        }).then(res => {
          if (res.status === 201) {
            this.$router.push('/admin/disciplinas')
          } else {
            this.error = 'Disciplina não foi criada'
            this.enviando = false
          }
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
        api.patch(`/disciplines/${this.id}`, {
          name: this.name
        }).then(res => {
          if (res.status === 200) {
            this.$router.push('/admin/disciplinas')
          } else {
            this.error = 'Disciplina não foi atualizada'
            this.enviando = false
          }
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      }
    }
  }
}
</script>
